import React from "react";
import { Video, VideoSection } from "./LeaguePlayers";
import VideoThumbnail from "./VideoThumbnail";

interface Props {
  sections: VideoSection[];
  onVideoSelected(video: Video): void;
}

export default function VideoSections({ sections, onVideoSelected }: Props) {
  return (
    <div>
      {sections.map((section) => (
        <div className="pl-5 mt-5" key={section.title}>
          <h1 className="text-lg font-bold mb-3">{section.title}</h1>
          <div className="flex flex-row overflow-auto">
            {section.videos.map((video) => (
              <button
                key={video.id}
                style={{ width: 335, marginRight: 10 }}
                className="self-start focus:outline-none"
                onClick={() => onVideoSelected(video)}
              >
                <VideoThumbnail
                  video={video}
                  width={335}
                  height={188}
                  variant="full"
                />
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
