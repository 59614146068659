import moment from "moment";
import React, { useContext } from "react";
import { TimeContext } from "./App";
import { Video } from "./LeaguePlayers";
import { sanitizeTitle } from "./PlayerPage";
import { formatViewers } from "./ViewerCount";

interface Props {
  video: Video;
  width: number;
  height?: number;
  variant: "without_title" | "full";
}

export default function VideoThumbnail({
  variant,
  video,
  width,
  height,
}: Props) {
  const { thumbnail_url, title, duration, view_count, at } = video;
  const now = useContext(TimeContext);

  const image =
    thumbnail_url && !thumbnail_url.includes("/_404/") ? (
      <img
        alt={title || "VOD Thumbnail"}
        loading="lazy"
        style={{
          minWidth: width,
          width: width,
          height: height,
          maxHeight: height,
          objectFit: "cover",
        }}
        src={thumbnail_url}
      />
    ) : (
      <div className="bg-black" style={{ width: width, height: height }} />
    );

  return (
    <div>
      <div style={{ position: "relative", marginBottom: 5 }}>
        {image}
        {duration && (
          <div
            className="text-sm absolute top-1 left-1 px-1 rounded-sm"
            style={{
              backgroundColor: "rgba(0,0,0,0.95)",
            }}
          >
            {formatDuration(duration)}
          </div>
        )}
        {view_count !== null && view_count !== undefined && (
          <div
            className="text-sm absolute bottom-1 left-1 px-1 rounded-sm"
            style={{
              backgroundColor: "rgba(0,0,0,0.95)",
            }}
          >
            {formatViewers(view_count)} views
          </div>
        )}
        <div
          className="text-sm absolute bottom-1 right-1 px-1 rounded-sm"
          style={{
            backgroundColor: "rgba(0,0,0,0.95)",
          }}
        >
          {moment(at).from(now)}
        </div>
      </div>
      {variant === "full" && title && (
        <div className="text-sm font-semibold text-left">
          {sanitizeTitle(title)}
        </div>
      )}
    </div>
  );
}

export function formatDuration(d: string) {
  const parts = d
    .split(".")[0]
    .toLowerCase()
    .replace("pt", "")
    .replaceAll(/h|m/g, ":")
    .replace("s", "")
    .split(":");
  return parts
    .map((p, index) => (index > 0 ? p.padStart(2, "0") : p))
    .join(":");
}
