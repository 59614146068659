import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
} from "@heroicons/react/20/solid";
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
interface Props {
  title: string;
  width: number;
}

export const SidebarContext = createContext(false);

export default function Sidebar({
  title,
  width,
  children,
}: PropsWithChildren<Props>) {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const listener = () => {
      const query = `(min-width: 768px)`;
      if (!window.matchMedia(query).matches) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };
    listener();
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  return (
    <div
      className="flex flex-col border-r-2 border-zinc-900"
      data-nosnippet={true}
    >
      <div className="justify-center px-6 py-3 hidden sm:flex">
        {!collapsed && (
          <div className="flex-1 font-semibold text-sm">{title}</div>
        )}
        <button onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? (
            <ArrowSmallRightIcon className="w-6 h-6" />
          ) : (
            <ArrowSmallLeftIcon className="w-6 h-6" />
          )}
        </button>
      </div>
      <div
        style={{
          width: collapsed ? "auto" : width,
        }}
        className={
          "overflow-x-scroll sm:overflow-x-hidden w-full flex sm:flex-col flex-row flex-1"
        }
      >
        <SidebarContext.Provider value={collapsed}>
          {children}
        </SidebarContext.Provider>
      </div>
    </div>
  );
}
