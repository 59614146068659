import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Avatar from "./Avatar";
import { LeagueEventSearch } from "./LeagueEvents";
import { SidebarContext } from "./Sidebar";
import { formatViewers } from "./ViewerCount";

export type Props = {
  event: LeagueEventSearch;
  selected: boolean;
};

export default function LeagueEventSummary({ event, selected }: Props) {
  const collapsed = useContext(SidebarContext);

  const live = event.status.live ? (
    <div className="flex justify-center text-sm">
      <div
        className={"w-2 h-2  self-center mr-1 -ml-2 rounded-full bg-red-500"}
      />
      {formatViewers(event.status.viewer_count)}
    </div>
  ) : (
    <div className="text-xs text-center">Offline</div>
  );

  const eventAvatarUrl = event.status.streams[0]?.stream_info.avatar_url;

  return (
    <Link to={`/lol-events/${event.id}`}>
      <div
        className={
          "hover:bg-zinc-600 cursor-pointer flex-1 h-full w-80 sm:w-full " +
          (selected ? "bg-zinc-600" : "")
        }
      >
        <div className="flex py-2 px-4">
          <div className="py-1">
            <div className="relative">
              <Avatar url={eventAvatarUrl} alt={event.name} size={45} />
            </div>
            <div className="mt-1">{live}</div>
          </div>
          {!collapsed && (
            <div className="px-6">
              <div>
                <div className="font-bold">{event.league}</div>
                <div className="text-xs mt-1">{event.name}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}
