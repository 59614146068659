import { VideoCameraIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import Avatar from "./Avatar";
import { LeagueEventSearch, StreamInfo } from "./LeagueEvents";
import { API_ENDPOINT, Video, VideoSection } from "./LeaguePlayers";
import { DISCLOSURE, sanitizeTitle } from "./PlayerPage";
import { TimeAgo } from "./Time";
import VideoSections from "./VideoSections";
import VideoThumbnail from "./VideoThumbnail";
import { formatViewers } from "./ViewerCount";

interface Props {
  selectedEventId?: string;
}

type LeagueEventTeam = {
  img_url: string;
  name: string;
  score: number;
};

type FullLeagueEvent = LeagueEventSearch & {
  matches: {
    team1: LeagueEventTeam;
    team2: LeagueEventTeam;
    reddit_url?: string | null;
    round_name?: string | null;
    scheduled_at?: string | null;
    games: {
      name: string;
      winner_team: string;
      videos: {
        short_name: string;
        video: Video;
      }[];
    }[];
  }[];
  video_sections: VideoSection[];
};

const VIDEO_NAME_MAP = new Map<string, string>([
  ["PB", "Pick/Ban"],
  ["Start", "Start"],
  ["HL", "Highlights"],
]);

export default function LeagueEvent({ selectedEventId }: Props) {
  const [playing, setPlaying] = useState<"stream" | "video">("stream");
  const [chatOpen] = useState(false);
  const [playingVideo, setPlayingVideo] = useState<Video>();
  const [playingStream, setPlayingStream] = useState<StreamInfo>();
  const [event, setEvent] = useState<FullLeagueEvent>();
  const [error, setError] = useState<React.ReactElement>();
  const { eventId } = useParams();
  const container = useRef<HTMLDivElement>(null);

  const loadEvent = useCallback(async () => {
    if (selectedEventId) {
      const r = await fetch(`${API_ENDPOINT}/lol/event?id=${selectedEventId}`);
      if (r.ok) {
        setEvent(await r.json());
        setError(undefined);
      } else {
        setError(EVENT_NOT_FOUND);
        setEvent(undefined);
      }
    }
  }, [selectedEventId]);

  const selectVideo = useCallback((video: Video) => {
    if (!video.embed_url && video.external_url) {
      window.open(video.external_url, "_blank");
      return;
    }
    setPlayingVideo(video);
    setPlaying("video");
    container.current?.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadEvent();
    const timer = setInterval(loadEvent, 60_000);
    return () => clearInterval(timer);
  }, [loadEvent]);

  const root = eventId === undefined;

  const helmet = (
    <Helmet>
      <title>{getTitle(root, event)}</title>
      <meta name="description" content={getDescription(root, event)} />
    </Helmet>
  );

  const recentMatches = useMemo(() => {
    if (!event) {
      return [];
    }
    return event.matches
      .filter((m) => m.games.flatMap((g) => g.videos).length > 0)
      .reverse();
  }, [event]);

  if (!event) {
    return (
      <div className="flex w-full">
        {helmet}
        {error && (
          <div className="flex flex-1">
            <h1 className="text-center self-center w-full text-xl font-bold">
              {error}
            </h1>
          </div>
        )}
      </div>
    );
  }

  let iframeSrc: string | undefined;
  let title;

  if (playing === "video" && playingVideo) {
    iframeSrc = playingVideo.embed_url || "";
    title = sanitizeTitle(playingVideo.title || "");
  } else {
    const stream = playingStream || event.status.streams[0]?.stream_info;
    iframeSrc = stream?.embed_url;
    if (stream?.site === "twitch" && chatOpen) {
      iframeSrc = iframeSrc?.replace("layout=video", "layout=video-with-chat");
    }
    title = sanitizeTitle(stream.title || "");
  }

  const live = event.status.live ? (
    <div className="flex justify-center text-sm">
      <div
        className={"w-2 h-2 bg-red-500 self-center mr-1 -ml-2 rounded-full"}
      />
      {formatViewers(event.status.viewer_count)}
    </div>
  ) : (
    <div className="text-sm text-center">Offline</div>
  );

  const eventAvatarUrl = event.status.streams[0]?.stream_info.avatar_url;
  const allLiveStreams = event.status.streams.filter((s) => s.stream_info.live);

  return (
    <div className="flex-1 overflow-auto" ref={container}>
      {helmet}
      {iframeSrc && (
        <div className="flex flex-col h-5/6">
          <iframe
            title="Currently Playing"
            width="100%"
            height="100%"
            src={iframeSrc}
            frameBorder={0}
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            scrolling="no"
            sandbox="allow-modals allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          />
        </div>
      )}
      <div className="flex py-5 px-4">
        <div className="py-1 px-4">
          <div className="relative">
            <Avatar url={eventAvatarUrl} alt={event.name} size={60} />
          </div>
          <div className="mt-1">{live}</div>
        </div>
        <div className="px-4 flex-1">
          <div>
            <div className="font-bold text-xl">{event.league}</div>
            <div className="">{event.name}</div>
            {title && <h2>{title}</h2>}
            {playingVideo?.at && (
              <div>
                <TimeAgo at={playingVideo.at} />
              </div>
            )}
            {playing === "stream" && allLiveStreams.length > 0 && (
              <div>
                Live Streams:
                <span>
                  {allLiveStreams.map((s) => (
                    <span
                      className="mx-1"
                      key={s.stream_info.site + s.stream_info.id}
                    >
                      <button onClick={() => setPlayingStream(s.stream_info)}>
                        {s.name} [{s.languages.join(", ")}]
                      </button>
                    </span>
                  ))}
                </span>
              </div>
            )}
          </div>
        </div>
        {playing === "video" && (
          <div className="text-right">
            <button
              onClick={() => {
                setPlayingStream(undefined);
                setPlaying("stream");
              }}
              className="hover:text-gray-200 flex gap-2 text-sm"
            >
              <VideoCameraIcon className="w-5 h-5" />
              Live Stream
            </button>
          </div>
        )}
      </div>

      {recentMatches.length > 0 && (
        <div className="px-6 mt-5 border-b-2 border-zinc-900">
          <h1 className="text-lg font-bold mb-3">Matches</h1>
          {recentMatches.map((match, idx) => {
            const firstMatchVideo = match.games[0]?.videos[0];
            return (
              <div className="flex border-t-2 border-zinc-900 py-4" key={idx}>
                <div className="flex-1 self-center text-center">
                  <button
                    className="hover:text-gray-300"
                    onClick={() => {
                      if (firstMatchVideo) {
                        selectVideo(firstMatchVideo.video);
                      }
                    }}
                  >
                    <span className="text-xl font-bold">
                      {match.team1.name}
                    </span>
                    <span> vs </span>
                    <span className="text-xl font-bold">
                      {match.team2.name}
                    </span>
                  </button>
                  {match.scheduled_at && (
                    <div className="text-sm">
                      {moment(match.scheduled_at).local().format("ddd, MMM Do")}{" "}
                      &middot; <TimeAgo at={match.scheduled_at} />
                    </div>
                  )}
                  {match.reddit_url && (
                    <div>
                      <a
                        href={match.reddit_url}
                        className="text-sm hover:text-gray-200"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Reddit Thread
                      </a>
                    </div>
                  )}
                </div>
                <div>
                  {firstMatchVideo && (
                    <button onClick={() => selectVideo(firstMatchVideo.video)}>
                      <VideoThumbnail
                        video={firstMatchVideo.video}
                        width={250}
                        height={150}
                        variant="without_title"
                      />
                    </button>
                  )}
                </div>
                <div className="flex-1">
                  {match.games
                    .filter((g) => g.videos.length > 0)
                    .map((game, idx) => {
                      const firstVideo = game.videos.find((g) =>
                        ["PB", "Start"].includes(g.short_name)
                      );
                      return (
                        <div className="px-5 mb-2" key={game.name}>
                          <div className="flex gap-2">
                            <button
                              className="hover:text-gray-300"
                              onClick={() => {
                                if (firstVideo) {
                                  selectVideo(firstVideo.video);
                                }
                              }}
                            >
                              Game {idx + 1}:
                            </button>
                            {game.videos.map((v, idx) => (
                              <div key={v.short_name}>
                                {idx > 0 && <span> &middot; </span>}
                                <button
                                  className="hover:text-gray-300"
                                  onClick={() => selectVideo(v.video)}
                                >
                                  {VIDEO_NAME_MAP.get(v.short_name) ||
                                    v.short_name}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {event.video_sections.length > 0 && (
        <VideoSections
          sections={event.video_sections}
          onVideoSelected={selectVideo}
        />
      )}
      {DISCLOSURE}
    </div>
  );
}

function getTitle(root: boolean, event?: FullLeagueEvent): string {
  if (root || !event) {
    return "League of Legends - Event Streams and VODs";
  }
  return event.league + " - Live Stream and VODs";
}

function getDescription(root: boolean, event?: FullLeagueEvent): string {
  if (root || !event) {
    return `Watch League of Legends event streams and VODs on prostreams.gg.`;
  }
  return `Watch ${event.league} streams and VODs on prostreams.gg. Now streaming ${event.name}.`.trim();
}

const EVENT_NOT_FOUND = (
  <div>
    <p>Sorry, this event was not found.</p>
    <p className="mt-5">
      If you think it should be on prostreams.gg,{" "}
      <a
        href="mailto:danxfang@gmail.com?subject=Missing event on prostreams.gg"
        target="_blank"
        rel="noreferrer"
        className="text-blue-300 hover:text-blue-400"
      >
        send me an email
      </a>
      .
    </p>
  </div>
);
