import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import "./index.css";
import LeagueEvents from "./LeagueEvents";
import LeaguePlayers from "./LeaguePlayers";
import ValorantPlayers from "./ValorantPlayers";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Navigate to="/" />,
    children: [
      {
        path: "",
        element: <LeaguePlayers />,
      },
      {
        path: "lol",
        element: <LeaguePlayers />,
      },
      {
        path: "lol/:playerId",
        element: <LeaguePlayers />,
      },
      {
        path: "valorant",
        element: <ValorantPlayers />,
      },
      {
        path: "valorant/:playerId",
        element: <ValorantPlayers />,
      },
      {
        path: "lol-events",
        element: <LeagueEvents />,
      },
      {
        path: "lol-events/:eventId",
        element: <LeagueEvents />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
