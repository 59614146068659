import {
  ArrowTopRightOnSquareIcon,
  ChatBubbleBottomCenterTextIcon,
  VideoCameraIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import Avatar from "./Avatar";
import CurrentMatchBadge from "./CurrentMatchBadge";
import {
  API_ENDPOINT,
  Player,
  ROLE_DISPLAY_NAMES,
  Video,
} from "./LeaguePlayers";
import { TimeAgo, TimeElapsedSince } from "./Time";
import VideoSections from "./VideoSections";
import ViewerCount from "./ViewerCount";

interface Props {
  game: "lol" | "valorant";
  selectedPlayerId?: string;
  banner?: React.ReactElement;
}

export default function PlayerPage({ game, selectedPlayerId, banner }: Props) {
  const [playing, setPlaying] = useState<"stream" | "video">("stream");
  const [chatOpen, setChatOpen] = useState(false);
  const [bannerOpen, setBannerOpen] = useState(true);
  const [playingVideo, setPlayingVideo] = useState<Video>();
  const [player, setPlayer] = useState<Player>();
  const [error, setError] = useState<React.ReactElement>();
  const container = useRef<HTMLDivElement>(null);
  const { playerId } = useParams();

  const loadPlayer = useCallback(async () => {
    if (selectedPlayerId) {
      const r = await fetch(
        `${API_ENDPOINT}/${game}/player?id=${selectedPlayerId}`
      );
      if (r.ok) {
        const player = await r.json();
        setPlayer(player);
        setError(undefined);
      } else {
        setPlayer(undefined);
        setError(PLAYER_NOT_FOUND);
      }
    }
  }, [game, selectedPlayerId]);

  useEffect(() => {
    setPlaying("stream");
    setPlayingVideo(undefined);
    loadPlayer();
    const timer = setInterval(loadPlayer, 60_000);
    return () => clearInterval(timer);
  }, [loadPlayer]);

  const root = playerId === undefined;

  const helmet = (
    <Helmet>
      <title>{getTitle(game, player, root)}</title>
      <meta name="description" content={getDescription(game, player, root)} />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );

  if (!player) {
    return (
      <div className="flex w-full">
        {helmet}
        <h1 className="p-4 text-center">{selectedPlayerId}</h1>
        {error && (
          <div className="flex flex-1">
            <h1 className="text-center self-center w-full text-xl font-bold">
              {error}
            </h1>
          </div>
        )}
      </div>
    );
  }

  let iframeSrc: string | undefined;
  let title;

  if (playing === "video" && playingVideo) {
    iframeSrc = playingVideo.embed_url || "";
    title = sanitizeTitle(playingVideo.title || "");
  } else {
    iframeSrc = player.stream_info?.embed_url;
    if (player.stream_info?.site === "twitch" && chatOpen) {
      iframeSrc = iframeSrc?.replace("layout=video", "layout=video-with-chat");
    }
    title = sanitizeTitle(player.stream_info?.title || "");
  }

  const description = [];

  if (player.type === "LeaguePlayer") {
    description.push(ROLE_DISPLAY_NAMES[player.role]);
    description.push(player.league || player.region);
  }

  if (player.type === "ValorantPlayer") {
    description.push(player.region);
  }

  if (player.team) {
    description.push(player.team);
  }

  return (
    <div className="flex-1 overflow-auto" ref={container}>
      {helmet}
      {bannerOpen && banner !== undefined && (
        <div className="bg-blue-600 font-bold flex">
          {banner}
          <button className="mr-5 text-xs" onClick={() => setBannerOpen(false)}>
            <XMarkIcon className="w-4 h-4" />
          </button>
        </div>
      )}
      {iframeSrc && (
        <div className="flex flex-col h-5/6">
          <iframe
            title={`${player.handle} Currently Playing`}
            width="100%"
            height="100%"
            src={iframeSrc}
            frameBorder={0}
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            scrolling="no"
            sandbox="allow-modals allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          />
        </div>
      )}
      <div className="flex py-3 px-5 mt-2 gap-5">
        <div className="flex flex-row">
          <div className="mr-5">
            <div>
              <Avatar
                url={player.stream_info?.avatar_url}
                alt={player.handle}
                size={70}
              />
            </div>
            {playing === "stream" && (
              <div className="mt-2">
                <ViewerCount stream={player.stream_info} />
              </div>
            )}
          </div>
          <div>
            <h1 className="text-xl font-bold ">{player.handle}</h1>
            <h2>{description.join(" · ")}</h2>
            {title && <h2>{title}</h2>}
            {playing === "video" && playingVideo?.at && (
              <div>
                <TimeAgo at={playingVideo.at} />
              </div>
            )}
            {playing === "stream" && player.stream_info?.started_at && (
              <div>
                Streaming for{" "}
                <TimeElapsedSince start={player.stream_info.started_at} />
              </div>
            )}
            {playing === "stream" &&
              player.type === "LeaguePlayer" &&
              player.current_match && (
                <CurrentMatchBadge
                  match={player.current_match}
                  variant="large"
                />
              )}
          </div>
        </div>
        <div className="flex-1 h-30"></div>
        <div>
          <div className="text-right flex gap-4">
            {playing === "video" && (
              <button
                onClick={() => setPlaying("stream")}
                className="hover:text-gray-200 flex gap-2 text-sm"
              >
                <VideoCameraIcon className="w-5 h-5" />
                Live Stream
              </button>
            )}
            {playing === "stream" && player.stream_info?.site === "twitch" && (
              <button
                onClick={() => setChatOpen(!chatOpen)}
                className="hover:text-gray-200"
              >
                <ChatBubbleBottomCenterTextIcon className="w-5 h-5" />
              </button>
            )}
            {playing === "stream" && player.stream_info?.external_url && (
              <a
                href={player.stream_info?.external_url}
                target="_blank"
                rel="noreferrer"
                className="hover:text-gray-200"
              >
                <ArrowTopRightOnSquareIcon className="w-5 h-5" />
              </a>
            )}
          </div>
        </div>
      </div>
      {player.video_sections && (
        <VideoSections
          sections={player.video_sections}
          onVideoSelected={(video) => {
            if (!video.embed_url && video.external_url) {
              window.open(video.external_url, "_blank");
              return;
            }
            setPlayingVideo(video);
            setPlaying("video");
            container.current?.scrollTo(0, 0);
          }}
        />
      )}
      {DISCLOSURE}
    </div>
  );
}

export function sanitizeTitle(t: string): string {
  return t
    .replace("【", "[")
    .replace("】", "] ")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&amp;/g, "&");
}

export const DISCLOSURE = (
  <div className="px-3 mt-10 mb-5 text-sm">
    <div>
      prostreams.gg isn't endorsed by Riot Games and doesn't reflect the views
      or opinions of Riot Games or anyone officially involved in producing or
      managing Riot Games properties. Riot Games, and all associated properties
      are trademarks or registered trademarks of Riot Games, Inc.
    </div>
    <div className="mt-5">
      YouTube functionality is provided by the YouTube API. By using
      prostreams.gg, you are also bound to YouTube's{" "}
      <a
        className="text-blue-300 hover:text-blue-400"
        href="https://www.youtube.com/t/terms"
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service
      </a>
      .
    </div>
  </div>
);

const PLAYER_NOT_FOUND = (
  <div>
    <p>Sorry, this player was not found.</p>
    <p className="mt-5">
      If you think they should be on prostreams.gg,{" "}
      <a
        href="mailto:danxfang@gmail.com?subject=Missing player on prostreams.gg"
        target="_blank"
        rel="noreferrer"
        className="text-blue-300 hover:text-blue-400"
      >
        send me an email
      </a>
      .
    </p>
  </div>
);

function getGameName(game: "lol" | "valorant"): string {
  switch (game) {
    case "lol":
      return "League of Legends";
    case "valorant":
      return "Valorant";
  }
}

function getRole(role: string | undefined): string {
  switch (role) {
    case "top":
      return "top laner";
    case "jungler":
      return "jungler";
    case "mid":
      return "mid laner";
    case "bot":
      return "bot laner";
    case "support":
      return "support";
    default:
      return "player";
  }
}

function getTitle(
  game: "lol" | "valorant",
  selected?: Player,
  root?: boolean
): string {
  if (!selected || root === true) {
    return getGameName(game) + " - Pro Streams and VODs";
  }
  return selected.handle + " - Live Stream and VODs";
}

function getDescription(
  game: "lol" | "valorant",
  selected?: Player,
  root?: boolean
): string {
  if (!selected || root === true) {
    return `Watch pro ${getGameName(game)} streams and VODs on prostreams.gg.`;
  }
  let details = "";
  if (selected.type === "LeaguePlayer" && selected.team) {
    details = `${selected.handle} is a ${getRole(selected.role)} for ${
      selected.team
    } in the ${selected.league}.`;
  } else if (selected.team) {
    details = `${selected.handle} is a professional player for ${selected.team}.`;
  }
  return `Watch ${selected.handle} and other professional ${getGameName(
    game
  )} streams and VODs on prostreams.gg. ${details}`.trim();
}
