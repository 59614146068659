import React, { PropsWithChildren } from "react";

interface Props {}

export default function SearchGroup({ children }: PropsWithChildren<Props>) {
  return (
    <div className="inline-flex flex-col lg:flex-row border-2 border-zinc-700 bg-zinc-800 px-3 py-2 rounded space-x-0 lg:space-x-5 space-y-2 lg:space-y-0">
      {children}
    </div>
  );
}
