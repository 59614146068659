import moment from "moment";
import React, { useContext } from "react";
import { TimeContext } from "./App";
import { formatDuration } from "./VideoThumbnail";

export function TimeElapsedSince({ start }: { start: string }) {
  const now = useContext(TimeContext);
  return (
    <span>
      {formatDuration(moment.duration(now.diff(start)).toISOString())}
    </span>
  );
}

export function TimeAgo({ at }: { at: string }) {
  const now = useContext(TimeContext);
  return <span>{moment.utc(at).from(now)}</span>;
}
