import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "./Header";
import { LeagueEventSearch, StreamInfo } from "./LeagueEvents";
import PlayerPage from "./PlayerPage";
import PlayerSearchResult from "./PlayerSearchResult";
import SearchGroup from "./SearchGroup";
import SearchInput from "./SearchInput";
import Select, { SelectOption } from "./Select";
import Sidebar from "./Sidebar";
import { formatViewers } from "./ViewerCount";

const production = true || process.env.NODE_ENV === "production";

export const API_ENDPOINT = production
  ? "https://api.prostreams.gg"
  : "http://localhost:5000";

export const LeagueOptions: SelectOption[] = [
  { name: "All Leagues", value: "all" },
  { name: "LCS", value: "LCS" },
  { name: "LEC", value: "LEC" },
  { name: "LCK", value: "LCK" },
  { name: "LPL", value: "LPL" },
  { name: "PCS", value: "PCS" },
  { name: "VCS", value: "VCS" },
  { name: "CBLOL", value: "CBLOL" },
  { name: "LJL", value: "LJL" },
  { name: "LLA", value: "LLA" },
];

export const RoleOptions: SelectOption[] = [
  { name: "All Roles", value: "all" },
  { name: "Top", value: "top" },
  { name: "Jungler", value: "jungler" },
  { name: "Mid", value: "mid" },
  { name: "Bot", value: "bot" },
  { name: "Support", value: "support" },
  { name: "Streamer", value: "streamer" },
  { name: "Other", value: "other" },
];

export type Role =
  | "top"
  | "jungler"
  | "mid"
  | "bot"
  | "support"
  | "other"
  | "streamer";

export const ROLE_DISPLAY_NAMES: Record<Role, string> = {
  top: "Top",
  jungler: "Jungler",
  mid: "Mid",
  bot: "Bot",
  support: "Support",
  other: "Other",
  streamer: "Streamer",
};

export type Video = {
  id: string;
  site: Site;
  external_url?: string | null;
  embed_url?: string | null;
  duration?: string | null;
  view_count?: number | null;
  thumbnail_url: string;
  at: string;
  title?: string | null;
};

export type CurrentMatch = {
  champion_image_url: string;
  champion_name: string;
  start_at: string;
};

export type Site = "twitch" | "douyu" | "huya" | "afreeca" | "youtube";

export type VideoSection = {
  title: string;
  videos: Video[];
};

type BasePlayer = {
  id: string;
  handle: string;
  live: boolean;
  name?: string | null;
  team?: string | null;
  avatar_url?: string;
  stream_info?: StreamInfo | null;
  video_sections?: VideoSection[];
};

export type LeaguePlayer = BasePlayer & {
  type: "LeaguePlayer";
  league?: string | null;
  region?: string | null;
  role: Role;
  current_match?: CurrentMatch | null;
};

export type ValorantPlayer = BasePlayer & {
  type: "ValorantPlayer";
  region: string;
};

export type Player = LeaguePlayer | ValorantPlayer;

export default function LeaguePlayers() {
  const [searchPlayer, setSearchPlayer] = useState("");
  const [searchLeague, setSearchLeague] = useState<SelectOption>();
  const [searchRole, setSearchRole] = useState<SelectOption>();
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState<LeaguePlayer[]>([]);
  const [liveEvents, setLiveEvents] = useState<LeagueEventSearch[]>([]);
  const [defaultPlayerId, setDefaultPlayerId] = useState();
  const { playerId } = useParams();
  const timerRef = useRef<NodeJS.Timer>();

  const search = useCallback(async () => {
    const params = new URLSearchParams();
    if (searchPlayer) {
      params.set("player", searchPlayer);
    }
    if (searchLeague && searchLeague.value !== "all") {
      params.set("leagues", searchLeague.value);
    }
    if (searchRole && searchRole.value !== "all") {
      params.set("roles", searchRole.value);
    }
    const r = await fetch(`${API_ENDPOINT}/lol/players?${params}`);
    const data = await r.json();
    const players = data.results;
    setPlayers(players);
    setLoading(false);
    setLiveEvents(data.live_events);
    setDefaultPlayerId((prev) => prev || players[0]?.id);
  }, [searchPlayer, searchLeague, searchRole]);

  useEffect(() => {
    search();
    clearInterval(timerRef.current);
    timerRef.current = setInterval(search, 60_000);
    return () => clearInterval(timerRef.current);
  }, [search]);

  const banner =
    liveEvents.length > 0 ? (
      <Link to={`/lol-events/${liveEvents[0].id}`} className="flex-1">
        <div className="text-center my-3">
          An official {liveEvents[0].league} event is live (
          {formatViewers(liveEvents[0].status.viewer_count)} viewers)
        </div>
      </Link>
    ) : undefined;

  const selectedPlayerId = playerId || defaultPlayerId;

  return (
    <>
      <Header width={250} section="lol">
        <SearchGroup>
          <div>
            <SearchInput
              placeholder="Search by player or team"
              onChangeText={setSearchPlayer}
            />
          </div>
          <div>
            <Select
              options={LeagueOptions}
              selected={searchLeague || LeagueOptions[0]}
              onSelected={setSearchLeague}
            />
          </div>
          <div>
            <Select
              options={RoleOptions}
              selected={searchRole || RoleOptions[0]}
              onSelected={setSearchRole}
            />
          </div>
        </SearchGroup>
      </Header>
      <div className="flex-1 flex sm:flex-row flex-col overflow-auto">
        <Sidebar title="Streamers" width={250}>
          {players.map((p) => (
            <PlayerSearchResult
              player={p}
              selected={selectedPlayerId === p.id}
              key={p.id}
            />
          ))}
          {!loading && players.length === 0 && NO_PLAYERS_FOUND}
        </Sidebar>
        <PlayerPage
          game="lol"
          selectedPlayerId={selectedPlayerId}
          banner={banner}
        />
      </div>
    </>
  );
}

export const NO_PLAYERS_FOUND = (
  <div className="py-1 px-5 text-sm">
    <p>No streamers found.</p>
    <p>
      <a
        href="mailto:danxfang@gmail.com?subject=Missing player on prostreams.gg"
        target="_blank"
        rel="noreferrer"
        className="text-blue-300 hover:text-blue-400"
      >
        Contact me
      </a>{" "}
      about missing players.
    </p>
  </div>
);

export function toggle(arr: string[], el: string): string[] {
  if (arr.includes(el)) {
    return arr.filter((e) => e !== el);
  }
  return [...arr, el];
}
