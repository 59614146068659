import moment, { Moment } from "moment";
import React, { useContext } from "react";
import { TimeContext } from "./App";
import { CurrentMatch } from "./LeaguePlayers";

interface Props {
  match: CurrentMatch;
  variant: "small" | "large";
}

export default function CurrentMatchBadge({ variant, match }: Props) {
  const now = useContext(TimeContext);

  if (variant === "small") {
    return (
      <div className="mt-1 mb-1">
        <div className="inline text-xs bg-green-300 text-black px-2 py-0.5 rounded-md whitespace-nowrap">
          {match.champion_name} {getStartTime(now, match.start_at)}
        </div>
      </div>
    );
  }

  return (
    <div className="inline-flex flex-row space-x-2 bg-green-300 text-black rounded-full pl-1 pr-3 py-0.5 mt-2 -ml-0.5 font-semibold">
      <img
        alt={match.champion_name}
        src={match.champion_image_url}
        className="rounded-full w-8 h-8"
      />
      <div className="self-center">
        {match.champion_name} · {getStartTime(now, match.start_at)}
      </div>
    </div>
  );
}

function getStartTime(now: Moment, start_str: string) {
  const start = moment(start_str);
  if (start.valueOf() === 0) {
    return "00:00";
  }
  const duration = moment.duration(now.diff(start));
  const mins = duration.get("minutes").toString().padStart(2, "0");
  const seconds = duration.get("seconds").toString().padStart(2, "0");
  return `${mins}:${seconds}`;
}
