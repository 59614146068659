import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Avatar from "./Avatar";
import CurrentMatchBadge from "./CurrentMatchBadge";
import { Player, ROLE_DISPLAY_NAMES } from "./LeaguePlayers";
import { SidebarContext } from "./Sidebar";
import ViewerCount from "./ViewerCount";

export type Props = {
  player: Player;
  selected: boolean;
};

export default function PlayerSearchResult({ player, selected }: Props) {
  const collapsed = useContext(SidebarContext);

  const descriptions = [];
  if (player.type === "LeaguePlayer") {
    descriptions.push(ROLE_DISPLAY_NAMES[player.role]);
    descriptions.push(player.league || player.region);
  }

  if (player.type === "ValorantPlayer" && player.region) {
    descriptions.push(player.region);
    if (player.team) {
      descriptions.push(player.team);
    }
  }

  const game = player.type === "LeaguePlayer" ? "lol" : "valorant";

  return (
    <Link to={`/${game}/${player.id}`}>
      <div
        className={`hover:bg-zinc-600 flex-1 h-full sm:w-full ${
          selected && "bg-zinc-600"
        }`}
      >
        <div className="flex py-1 px-5">
          <div className="py-1">
            <div className="relative">
              <Avatar url={player.avatar_url} alt={player.handle} size={45} />
              {player.type === "LeaguePlayer" && player.current_match && (
                <img
                  alt={player.current_match.champion_name}
                  src={player.current_match.champion_image_url}
                  className="rounded-full w-8 h-8 absolute -right-1.5 -bottom-2 border-green-300 border-2"
                />
              )}
            </div>
            <div
              className={
                player.type === "LeaguePlayer" && player.current_match
                  ? "mt-2"
                  : "mt-1"
              }
            >
              <ViewerCount stream={player.stream_info} />
            </div>
          </div>
          {!collapsed && (
            <div className="px-5 whitespace-nowrap">
              <div>
                <div className="font-bold text-lg">{player.handle}</div>
                <div className="text-sm">{descriptions.join(" · ")}</div>
                {player.type === "LeaguePlayer" && player.team && (
                  <div className="text-sm">{player.team}</div>
                )}
                {player.type === "LeaguePlayer" && player.current_match && (
                  <CurrentMatchBadge
                    variant="small"
                    match={player.current_match}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}
