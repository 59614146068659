import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import Contact from "./Contact";

export type Section = "lol" | "valorant" | "lol_events";

const SECTIONS = [
  { section: "lol", to: "/lol", name: "LoL" },
  { section: "lol_events", to: "/lol-events", name: "LoL Events" },
  { section: "valorant", to: "/valorant", name: "Valorant" },
];

interface Props {
  width: number;
  section: Section;
}

export default function Header({
  width,
  section,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className="py-3 border-b-2 border-zinc-900 flex">
      <div
        className="hidden md:block pl-6 pr-2"
        style={{
          width: width,
        }}
      >
        <Link to="/">
          <p className="font-bold text-lg">prostreams.gg</p>
        </Link>
        <div className="text-sm -ml-1 py-1 pl-1 pr-2">
          {SECTIONS.map((s, idx) => (
            <span key={s.section}>
              {idx > 0 && <span> &middot; </span>}
              <Link
                to={s.to}
                className={`py-1 border-indigo-400 hover:border-b-2 ${
                  section === s.section && "border-b-2 "
                }`}
              >
                {s.name}
              </Link>
            </span>
          ))}
        </div>
      </div>
      <div className="flex-1">{children}</div>
      <Contact />
    </div>
  );
}
