import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import Header from "./Header";
import {
  API_ENDPOINT,
  NO_PLAYERS_FOUND,
  ValorantPlayer,
} from "./LeaguePlayers";
import PlayerPage from "./PlayerPage";
import PlayerSearchResult from "./PlayerSearchResult";
import SearchGroup from "./SearchGroup";
import SearchInput from "./SearchInput";
import Sidebar from "./Sidebar";

function ValorantPlayers() {
  const [searchPlayer, setSearchPlayer] = useState("");
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState<ValorantPlayer[]>([]);
  const [defaultPlayerId, setDefaultPlayerId] = useState();
  const { playerId } = useParams();
  const timerRef = useRef<NodeJS.Timer>();

  const search = useCallback(async () => {
    const params = new URLSearchParams();
    if (searchPlayer) {
      params.set("player", searchPlayer);
    }
    const r = await fetch(`${API_ENDPOINT}/valorant/players?${params}`);
    const data = await r.json();
    const players = data.results;
    setPlayers(players);
    setLoading(false);
    setDefaultPlayerId((prev) => prev || players[0]?.id);
  }, [searchPlayer]);

  useEffect(() => {
    search();
    clearInterval(timerRef.current);
    timerRef.current = setInterval(search, 60_000);
    return () => clearInterval(timerRef.current);
  }, [search]);

  const selectedPlayerId = playerId || defaultPlayerId;

  return (
    <>
      <Header width={250} section="valorant">
        <SearchGroup>
          <div>
            <SearchInput
              placeholder="Search by player or team"
              onChangeText={setSearchPlayer}
            />
          </div>
        </SearchGroup>
      </Header>
      <div className="flex-1 flex sm:flex-row flex-col overflow-auto">
        <Sidebar title="Streamers" width={250}>
          {players.map((p) => (
            <PlayerSearchResult
              player={p}
              selected={selectedPlayerId === p.id}
              key={p.id}
            />
          ))}
          {!loading && players.length === 0 && NO_PLAYERS_FOUND}
        </Sidebar>
        <PlayerPage game="valorant" selectedPlayerId={selectedPlayerId} />
      </div>
    </>
  );
}

export default ValorantPlayers;
