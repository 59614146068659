import React from "react";

const PLACEHOLDER_IMG_SRC = "https://generative-placeholders.glitch.me/image";

export default function Avatar(props: {
  url?: string | null;
  alt: string;
  size: number;
}) {
  const { url, alt, size } = props;

  const avatarSrc =
    url ||
    `${PLACEHOLDER_IMG_SRC}?width=${size}&height=${size}&style=triangles&gap=${size}&colors=1&img=${alt}`;

  return (
    <img
      alt={alt}
      src={avatarSrc}
      className="rounded-full"
      loading="lazy"
      style={{
        backgroundColor: "white",
        width: size,
        objectFit: "cover",
        maxWidth: size,
        height: size,
        maxHeight: size,
      }}
    />
  );
}
