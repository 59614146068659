import React from "react";
import { StreamInfo } from "./LeagueEvents";

interface Props {
  stream?: StreamInfo | null;
}

export default function ViewerCount({ stream }: Props) {
  if (stream?.live) {
    return (
      <div
        className="flex justify-center text-sm"
        title={stream.replay ? "Replay" : "Live"}
      >
        <div
          className={
            "w-2 h-2 bg-red-500 self-center mr-1 -ml-2 rounded-full " +
            (stream.replay ? "bg-blue-500" : "bg-red-500")
          }
        />
        {formatViewers(stream.live_viewer_count || 0)}
      </div>
    );
  }

  return <div className="text-center text-xs">Offline</div>;
}

export function formatViewers(v: number) {
  if (v < 1000) {
    return v.toString();
  }
  if (v < 100_000) {
    return (v / 1000).toFixed(1) + "K";
  }
  if (v < 1_000_000) {
    return (v / 1000).toFixed(0) + "K";
  }
  return (v / 1_000_000).toFixed(1) + "M";
}
