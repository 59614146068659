import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import React from "react";

interface Props {
  placeholder?: string;
  onChangeText(value: string): any;
}

export default function SearchInput({ placeholder, onChangeText }: Props) {
  return (
    <div>
      <div className="rounded-md px-3 py-1.5 text-sm bg-zinc-600 flex">
        <MagnifyingGlassIcon className="h-3 w-3 self-center mr-3" />
        <input
          className="bg-zinc-600 placeholder:text-gray-300 w-60 focus:outline-none"
          placeholder={placeholder || "Search"}
          onChange={(t) => onChangeText(t.currentTarget.value)}
        />
      </div>
    </div>
  );
}
