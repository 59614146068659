import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import LeagueEvent from "./LeagueEvent";
import LeagueEventSummary from "./LeagueEventSummary";
import { API_ENDPOINT, Site } from "./LeaguePlayers";
import Sidebar from "./Sidebar";

export type StreamInfo = {
  id: string;
  site: Site;
  replay: boolean;
  external_url: string;
  embed_url: string;
  live_viewer_count?: number | null;
  title?: string | null;
  started_at?: string | null;
  avatar_url?: string | null;
  live?: boolean | null;
};

export type LeagueEventStream = {
  name: string;
  stream_info: StreamInfo;
  languages: string[];
  official: boolean;
};

export type LeagueEventSearch = {
  id: string;
  name: string;
  start_date: string;
  end_date: string;
  league: string;
  status: {
    live: boolean;
    viewer_count: number;
    streams: LeagueEventStream[];
  };
};

function LeagueEvents() {
  const [events, setEvents] = useState<LeagueEventSearch[]>([]);
  const [defaultEventId, setDefaultEventId] = useState();
  const { eventId } = useParams();
  const timerRef = useRef<NodeJS.Timer>();

  const loadEvents = useCallback(async () => {
    const r = await fetch(`${API_ENDPOINT}/lol/events`);
    const data = await r.json();
    setEvents(data.results);
    setDefaultEventId((prev) => prev || data.results[0]?.id);
  }, []);

  useEffect(() => {
    loadEvents();
    clearInterval(timerRef.current);
    timerRef.current = setInterval(loadEvents, 60_000);
    return () => clearInterval(timerRef.current);
  }, [loadEvents]);

  const selectedEventId = eventId || defaultEventId;

  return (
    <>
      <Header width={300} section="lol_events" />
      <div className="flex-1 flex sm:flex-row flex-col overflow-auto">
        <Sidebar title="Events" width={300}>
          {events.map((e) => (
            <LeagueEventSummary
              event={e}
              selected={selectedEventId === e.id}
              key={e.id}
            />
          ))}
        </Sidebar>
        <LeagueEvent selectedEventId={selectedEventId} />
      </div>
    </>
  );
}

export default LeagueEvents;
