import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

export const TimeContext = createContext(moment());
function App() {
  const [now, setNow] = useState(moment());

  useEffect(() => {
    const timer = setInterval(() => setNow(moment()), 1_000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="h-full flex flex-col bg-zinc-800 text-white">
      <TimeContext.Provider value={now}>
        <Outlet />
      </TimeContext.Provider>
    </div>
  );
}

export default App;
